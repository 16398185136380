<template>
  <v-container class="pt-0 pb-0 pl-0 pr-0">
    <v-textarea
      class="hnr-input"
      :value="text"
      outlined
      single-line
      dense
      @input="changeContent"
      :label="label"
      :name="name"
      :height="height"
      :disabled="disabled"
    ></v-textarea>
  </v-container>
</template>

<script>
export default {
  props: {
    height: { type: String, default: "" },
    name: { type: String, default: "" },
    color: { type: String, default: "" },
    text: { type: String, default: null },
    label: { type: String, default: null },
    validate: { type: Object, default: null },
    formElement: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      submitted: false
      // value: ""
    };
  },
  components: {},
  methods: {
    changeContent(value) {
      this.$emit("input", value)
    }
  }
};
</script>

<style lang="scss">
legend {
  display: none;
}

.hnr-input {
  font-size: 14px;
}
</style>
