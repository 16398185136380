<template>
  <v-container>
    <v-row class="align-center justify-end">
      <v-btn
          color="primary"
          dark
          class="mb-2 text-body-2"
          @click="goToCreateSlider"
      >{{ addSlide }}
      </v-btn>
    </v-row>
    <v-data-table
        v-if="topSliderConf && topSliderConf.length > 0"
        :headers="headers"
        :items="topSliderConf"
        class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:item.image_src="{ item }">
        <v-img
            :src="item.image_src"
            :alt="item.title"
            :title="item.title"
            width="200"
            height="200"
        ></v-img>
      </template>
      <template v-slot:item.slider_list="{ item }">
        <ul>
          <li
              v-for="data_list in item.slider_list"
              :key="data_list.id"
              class="mt-2"
          >
            {{ data_list.content }}
          </li>
        </ul>
      </template>
    </v-data-table>
    <create-top-slider-dialog
        :sliderId="currentSliderId"
        :dialog="showCreateSliderDialog"
        @onClickOutside="showCreateSliderDialog = false"
        @closePopup="closePopup"
    ></create-top-slider-dialog>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_HOME_PAGE_CONF,
  GET_TOP_SLIDER_CONF,
  SAVE_CONF,
  DELETE_SLIDE
} from "@/store/setting.module";
import CreateTopSliderDialog from "@/views/dialogs/create-top-slider-dialog";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

export default {
  data() {
    return {
      headers: [
        {text: "STT", value: "id"},
        {text: "Tên", value: "title"},
        {text: "Ảnh", value: "image_src", sortable: false},
        {text: "Nội Dung", value: "slider_list", sortable: false},
        {text: "Sửa/Xóa", value: "actions", sortable: false}
      ],
      editedIndex: -1,
      cardImg: "",
      editedItem: {
        word: "",
        example: "",
        translate: "",
        note: "",
        cardImg: ""
      },
      defaultItem: {
        word: "",
        example: "",
        transalte: "",
        note: "",
        images: {}
      },
      image: {},
      currentSliderId: 0,
      showCreateSliderDialog: false,
      addSlide: "Thêm slider",
    };
  },
  computed: {
    ...mapGetters({
      topSliderConf: "topSliderConf"
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New card" : "Edit card";
    },
    submitTypeName() {
      return this.editedIndex === -1 ? "Save" : "Update";
    }
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Cấu hình Trang chủ", titledad: 'Cấu hình'}]);
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  components: {
    CreateTopSliderDialog
  },
  methods: {
    goToCreateSlider() {
      this.currentSliderId = this.topSliderConf ? this.topSliderConf.length : 0;
      this.showCreateSliderDialog = true;
    },
    editItem(item) {
      this.$store.commit(GET_TOP_SLIDER_CONF, item.id);
      this.showCreateSliderDialog = true;
    },
    async deleteItem(item) {
      this.$store.commit(DELETE_SLIDE, item);
      let data = {
        key: 'slider_homepage',
        value: this.topSliderConf
      }
      confirm("Are you sure you want to delete this item?") &&
      await this.$store.dispatch(SAVE_CONF, data)
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    onChangeFileUpload() {
    },
    clearImage() {
    },
    closePopup() {
      this.reloadConfig();
      this.showCreateSliderDialog = false;
    },
    reloadConfig() {
      this.$store.dispatch(GET_HOME_PAGE_CONF);
    }
  }
};
</script>

<style lang="scss"></style>
