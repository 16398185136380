import ApiService from "@/common/api.service";

// action types
export const GET_LESSON_DETAIL = "/lesson/detail";
export const GET_LIST_LESSON = "/lesson/list";
export const SAVE_LESSON = "/lesson/save";
export const UPDATE_LESSON = "/lesson/update";
export const UPDATE_LESSON_ORDER = "/lesson/order/update";
export const UPDATE_LESSON_TYPE = "/lesson/type/update";
export const UPDATE_LESSON_TITLE = "/lesson/title/update";
export const DELETE_LESSON = "/lesson/delete";
export const UPLOAD_LESSON_VIDEO = "/lesson/video/upload";
export const UPDATE_IS_FREE = "/lesson/update-is-free";


// mutation types
export const SET_LESSON_DETAIL = "setLessonDetail";
export const SET_LESSON_LIST = "setListLesson";
export const SET_PERCENT_UPLOAD = "setPercentUpload";

const defaultLesson = {
  title: "",
  description: "",
  type: 0,
  content: "",
  slug: "",
  reference_id: null,
  lesson_index: null,
  medias: [],
  course_id: null,
}
const state = {
  lesson: {},
  lessons: [],
  percentUpload: 0,
  time: 0
}
// getters
const getters = {
  lessonDetail(state) {
    return state.lesson
  },
  percentUpload(state) {
    return state.percentUpload
  },
  timeUpload(state) {
    return state.time
  }
}
// actions
const actions = {
  [GET_LESSON_DETAIL](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_LESSON_DETAIL, payload)
        .then(({ data }) => {
          if (data.status) {
            console.log(data,"res data")
            context.commit(SET_LESSON_DETAIL, data);
          }
          resolve(data);
        })
    });
  },
  [GET_LIST_LESSON](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_LIST_LESSON, payload)
        .then(({ data }) => {
          if (data.status) {
            let lessons = data.data
            context.commit(SET_LESSON_LIST, lessons);
          }
          resolve(data);
        })
    });
  },
  [SAVE_LESSON](context, payload) {

    return new Promise((resolve) => {
      ApiService.post(SAVE_LESSON, payload)
        .then(({ data }) => {
          resolve(data)
        })

    });
  },
  [UPDATE_LESSON](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(UPDATE_LESSON, payload)
        .then(({ data }) => {
          resolve(data)
        })
    });
  },
  [UPDATE_IS_FREE](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(UPDATE_IS_FREE, payload)
        .then(({ data }) => {
          resolve(data)
        })
    });
  },
  [UPDATE_LESSON_ORDER](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(UPDATE_LESSON_ORDER, payload)
        .then(({ data }) => {
          resolve(data)
        })

    });
  },
  [UPDATE_LESSON_TITLE](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(UPDATE_LESSON_TITLE, payload)
        .then(({ data }) => {
          resolve(data)
        })

    });
  },
  [UPDATE_LESSON_TYPE](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(UPDATE_LESSON_TYPE, payload)
        .then(({ data }) => {
          resolve(data)
        })

    });
  },
  [DELETE_LESSON](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(DELETE_LESSON, payload)
        .then(({ data }) => {
          resolve(data)
        })

    });
  },
  [UPLOAD_LESSON_VIDEO](context, payload) {
    return new Promise((resolve, reject) => {
      const config = {
        // onUploadProgress: progressEvent =>  context.commit(SET_PERCENT_UPLOAD, progressEvent)
        onUploadProgress: function(progressEvent) {
          context.commit(SET_PERCENT_UPLOAD, progressEvent);
        }
      };

      ApiService.post(UPLOAD_LESSON_VIDEO, payload, config)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
}
// mutations
const mutations = {
  [SET_LESSON_DETAIL](state, data) {
    if (data.data != null) {
      state.lesson = data.data;
    }
    else {
      state.lesson = Object.assign({},defaultLesson);
    }
    console.log(state.lesson,"lesson")
  },
  [SET_LESSON_LIST](state, data) {
    state.lessons = data;
  },
  [SET_PERCENT_UPLOAD](state, proccessEvent) {
    state.percentUpload = proccessEvent.loaded * 100 / proccessEvent.total;
    state.time = proccessEvent.timeStamp;
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
