<template>
  <ValidationObserver ref="add_question">
<!--    <v-form ref="form" lazy-validation-->
<!--            @submit.prevent="handleSubmit(saveIntroTeacherConfig)"-->
<!--            method="post"-->
<!--            id="check-infoTeacher">-->
      <v-container>
        <v-row>
          <v-col cols="6">
            <span class="text-body-1">1. {{ addTitle }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <ValidationProvider
                name="teacher-title"
                rules="required"
                v-slot="{ errors }"
            >
            <hnr-input
                :name="'teacher-title'"
                v-model="intro.title"
                :text="intro.title"
                :label="'Nhập title'"
                @input="updateIntroTeacherConf"
            ></hnr-input>
              <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <span class="text-body-1"
            >2. {{ addImg }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <ValidationProvider
                name="imageTeacher"
                rules="required"
                v-slot="{ errors }"
            >
            <v-file-input
                :name="'imageTeacher'"
                class="pl-1"
                prepend-icon="mdi-camera"
                v-model="image"
                label="Tải ảnh"
                @change="onChangeFileUpload"
                @click:clear="clearImage"
            ></v-file-input>
            <div id="preview" v-if="show">
              <v-img :src="intro.image_src"></v-img>
            </div>
            <span v-else class="validate-error text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <span class="text-body-1">3. {{ addContent }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <ck-content  :content="intro.content" @getData="getContent"></ck-content>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-btn color="primary" type="submit" form="check-infoTeacher" @click="saveIntroTeacherConfig">{{ save }}</v-btn>
        </v-row>
      </v-container>
<!--    </v-form>-->
  </ValidationObserver>
</template>

<script>
import {mapGetters} from "vuex";
import {UPLOAD_IMAGE} from "@/store/media.module";
import {SAVE_CONF} from "@/store/setting.module";
import CkContent from "@/components/ck-content";
import HnrInput from "@/components/elements/hnr-input";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

export default {
  data() {
    return {
      rules: [
        value => !!value,
      ],
      show: false,
      image: {},
      intro: {
        title: "",
        image_src: null,
        content: ""
      },
      imageError: "",
      addTitle: 'Thêm title',
      addImg: 'Thêm ảnh giới thiệu (kích thước 630px x 590px;)',
      addContent: 'Thêm nội dung giới thiệu',
      save: 'Lưu'
    };
  },
  mounted() {
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Cấu hình Trang chủ", titledad: 'Cấu hình'}]);
  },
  computed: {
    ...mapGetters(["imageUrl", "introTeacherConf"])
  },
  watch: {
    imageUrl() {
      if (this.intro) {
        this.intro.image_src = this.imageUrl;
        if (this.intro.image_src) {
          this.show = true;
        }
        this.updateIntroTeacherConf();
      }
    },
    introTeacherConf: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.intro = newVal;
          if (this.intro && this.intro.image_src) {
            this.show = true;
          }
        }
      }
    }
  },
  components: {
    CkContent,
    HnrInput
  },
  methods: {
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(UPLOAD_IMAGE, formData);
    },
    clearImage() {
      this.intro.image_src = "";
      this.show = false;
      this.updateIntroTeacherConf();
    },
    getContent(content) {
      this.intro.content = content;
      this.updateIntroTeacherConf();
    },
    updateIntroTeacherConf() {
    },
    resetIntro() {
      this.intro.title = "";
      this.intro.image_src = null;
      this.intro.content = "";
    },
    async saveIntroTeacherConfig() {
      let result = await this.$refs.add_question.validate()
      if (result) {
        this.image = null;
        if (!this.intro.content) {
          this.$toasted.error('Không được bỏ trống nội dung giới thiệu', {
            position: "top-right",
            theme: "toasted-primary",
            duration: 3000
          });
          return false
        }
        let data = {
          key: 'teacher_intro',
          value: this.intro
        }
        this.$store.dispatch(SAVE_CONF, data).then((data) => {
          if (data.status) {
            this.$toasted.success('Lưu thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration: 1000
            });
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
#preview {
  height: auto;
  width: auto;
}
</style>
