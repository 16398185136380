import ApiService from "@/common/api.service";

export const GET_LIST_CATEGORY = "/categories/list";
export const GET_DETAIL_CATEGORY = "/categories/detail";
export const SAVE_CATEGORY = "/categories/save";
export const UPDATE_CATEGORY = "/categories/update";
export const DELETE_CATEGORY = "/categories/delete";
export const UPLOAD_CATEGORY_IMAGE = "/categories/image/upload";


export const SET_LIST_CATEGORY = "setListCategory";
export const SET_DETAIL_CATEGORY = "setDetailCategory";


const state = {
    categories: [],
    category: {},
};

const getters = {
    categories(state) {
        return state.categories;
    },
    categoryDetail(state) {
        return state.category;
    },
};

const actions = {
    [GET_LIST_CATEGORY](context) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_CATEGORY).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_CATEGORY, data);
                }
                resolve(data);
            });
        });
    },
    [GET_DETAIL_CATEGORY](context, id) {
        return new Promise(resolve => {
            ApiService.query(`${GET_DETAIL_CATEGORY}/${id}`).then(({data}) => {
                if (data.status) {
                    context.commit(SET_DETAIL_CATEGORY, data);
                }
                resolve(data);
            });
        });
    },
    [UPLOAD_CATEGORY_IMAGE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPLOAD_CATEGORY_IMAGE, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [SAVE_CATEGORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_CATEGORY, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [UPDATE_CATEGORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_CATEGORY, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [DELETE_CATEGORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(DELETE_CATEGORY, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
};

const mutations = {
    [SET_LIST_CATEGORY](state, data) {
        state.categories = data.data;
    },
    [SET_DETAIL_CATEGORY](state, data) {
        state.category = data.data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
