import ApiService from "@/common/api.service";

// action types
export const GET_LIST_FLASHCARD = "/flashcard/list";
export const SAVE_FLASHCARD = "/flashcard/save";
export const UPDATE_FLASHCARD = "/flashcard/update";
export const DELETE_FLASHCARD = "/flashcard/delete";
export const UPLOAD_FLASHCARD_IMAGE = "/flashcard/image/upload";
export const UPLOAD_FILE = "/file/upload";

// mutation types
export const SET_LIST_FLASHCARD = "setListFlashcard";

const state = {
  flashcards: []
};
// getters
const getters = {};
// actions
const actions = {
  [GET_LIST_FLASHCARD](context,payload) {
    return new Promise(resolve => {
      ApiService.query(GET_LIST_FLASHCARD, payload).then(({ data }) => {
        if (data.status) {
          context.commit(SET_LIST_FLASHCARD, data);
        }
        resolve(data);
      });
    });
  },
  [UPLOAD_FLASHCARD_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPLOAD_FLASHCARD_IMAGE, payload)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SAVE_FLASHCARD](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(SAVE_FLASHCARD, payload)
        .then(({ data }) => {
          resolve(data)
        })

    });
  },
  [UPDATE_FLASHCARD](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(UPDATE_FLASHCARD, payload)
        .then(({ data }) => {
          resolve(data)
        })

    });
  },
  [DELETE_FLASHCARD](context,payload) {
    return new Promise(resolve => {
      ApiService.post(DELETE_FLASHCARD, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },
  // upload file
  async [UPLOAD_FILE](context, payload) {
    return new Promise(resolve => {
      ApiService.post(UPLOAD_FILE, payload).then(({data}) => {
        resolve(data);
      });
    });
  }
};
// mutations
const mutations = {
  [SET_LIST_FLASHCARD](state, data) {
    state.flashcards = data.data;
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
