<template>
  <div id="app">
    <ValidationProvider v-if="isValidate" rules="required" v-slot="{ errors }">
      <ckeditor
        :editor="editor"
        :value="content"
        :config="editorConfig"
        @input="changeContent"
      ></ckeditor>
      <div v-if="errors[0] && validateMessage" class="validate-error text-danger text-danger">
        {{ validateMessage }}
      </div>
      <div v-else class="validate-error text-danger text-danger">
        {{ errors[0] }}
      </div>
    </ValidationProvider>

    <ckeditor
        v-if="!isValidate"
        :editor="editor"
        :value="content"
        :config="editorConfig"
        @input="changeContent"
    ></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor";
import UploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import UnderLine from "@ckeditor/ckeditor5-basic-styles/src/underline";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder";
import List from "@ckeditor/ckeditor5-list/src/list";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation";

export default {
  props: {
    validateMessage: String,
    content: String,
    name: String,
    isValidate: Boolean,
  },
  computed: {
    // cssProps() {
    //   return {
    //     '--height': this.height
    //   }
    // }
  },
  methods: {
    changeContent(value) {
      this.$emit("getData", value);
    }
  },
  data: function() {
    return {
      // height:"500px",
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        allowedContent: true,
        placeholder: "Nhập nội dung",
        plugins: [
          EssentialsPlugin,
          Alignment,
          FontSize,
          FontFamily,
          FontColor,
          FontBackgroundColor,
          UploadAdapter,
          Autoformat,
          BoldPlugin,
          ItalicPlugin,
          Strikethrough,
          UnderLine,
          Heading,
          List,
          CodeBlock,
          CKFinder,
          EasyImage,
          SimpleUploadAdapter,
          Image,
          ImageCaption,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          LinkPlugin,
          Table,
          TableToolbar,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          TextTransformation
        ],

        toolbar: [
          "heading",
          "|",
          "fontfamily",
          "fontsize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "alignment",
          "|",
          "numberedList",
          "bulletedList",
          "|",
          "indent",
          "outdent",
          "|",
          "link",
          "codeBlock",
          "imageUpload",
          "insertTable",
          "mediaEmbed",
          "|",
          "undo",
          "redo"
          // "|",
          // "ckfinder"
        ],
        image: {
          styles: ["full", "alignLeft", "alignCenter", "alignRight"],
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "imageTextAlternative",
            "imageStyle:full",
            "imageResize"
          ]
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: process.env.VUE_APP_API_URL + "/ckeditor/image/upload",
          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            Authorization: "Bearer <JSON Web Token>"
          }
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "heading",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: "h1",
              title: "heading1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: "h2",
              title: "heading2",
              class: "ck-heading_heading2"
            },
            {
              model: "heading3",
              view: "h3",
              title: "heading3",
              class: "ck-heading_heading3"
            }
          ]
        },
        alignment: {
          options: ["left", "right", "center"]
        },
        ckfinder: {
          // Open the file manager in the pop-up window.
          uploadUrl: process.env.VUE_APP_API_URL + "/ckeditor/image/upload",
          options: {
            resourceType: "Images"
          }
        }
      },
      dictionary: {
        custom: {
          title: {
            required: () => "タイトルを入力してください。",
            max: "タイトルは５０文字以内で入力してください。",
          },
          editorData: {
            required: () => "本文を入力してください。",
          }
        }
      },
      isShowConfirmDialog: false,
      startupFocus:true,
      validationInputTag: [
        {
          classes: "min-length",
          rule: tag => tag.text.length <= 10
        }
      ]
    };
  }
};
</script>

<style scoped>
/* .ck-content {
  height: var(--height);
} */
</style>
