import ApiService from "@/common/api.service";

// action types
export const GET_LIST_STUDENT = "/student/list";
export const GET_LIST_STUDENT_BY_COURSE = "/student/list-by-course";
export const GET_STUDENTS = "/students";
export const GET_LIST_USER_GIFT = "/user-gift/list";
export const COUNT_STUDENT = "/student/count";
export const GET_LIST_TEACHER = "/teacher/list";
export const COUNT_TEACHER = "/teacher/count";
export const GET_USER_INFO = "/user/getUser";
export const UPDATE_USER_INFO = "/user/update";
export const DELETE_USER = "/user/delete";
export const UPDATE_USER_TYPE = "/student/update";
export const GET_STUDENT_SEARCH_RESULT = "/student/search";
export const GET_TEACHER_SEARCH_RESULT = "/teacher/searchTeacher";
export const GET_LIST_ADMIN = "/admin/list";
export const GET_ADMIN_SEARCH_RESULT = "/admin/search";
export const BANNED_USER = "/user/banned";
export const GET_LESSON_PROCESS = "/user/lesson/process";
export const GET_LIST_AFFILIATE_CODE = "/affiliate/list-active-code";
export const CREATE_AFFILIATE_CODE = "/affiliate/create";
export const GET_HISTORY_BOOK_LESSON = "/user/get-history-book-lesson";
export const GET_HISTORY_BOOK_LESSON_TEACHER = "/user/get-history-book-lesson-teacher";


// mutation types
export const SET_LIST_LESSON_PROCESS = "setListLessonProcess";
export const SET_LIST_ADMIN = "setListAdmin";
export const SET_HISTORY_BOOK_LESSON = "setListLesson";
export const SET_HISTORY_BOOK_LESSON_TEACHER = "setListLessonTeacher";
export const SET_LIST_USER_GIFT = "setListUserGift";
export const SET_USER_INFO = "setUserInfo";
export const SET_LIST_STUDENT = "setListStudent";
export const SET_COUNT_STUDENT = "setCountStudent";
export const SET_LIST_TEACHER = "setListTeacher";
export const SET_COUNT_TEACHER = "setCountTeacher";
export const SET_LIST_AFFILIATE_CODE = "setListAffiliateCode";

const state = {
    admin: [],
    students: [],
    user_gifts: [],
    student_count: 0,
    teachers: [],
    teacher_count: 0,
    user: {},
    lesson_process: [],
    list_code: [],
    listLessonStudents: [],
    listLessonTeacher: []
};
const getters = {
    listStudents(state) {
        return state.students;
    },
    listLessonStudents(state) {
        return state.listLessonStudents;
    },
    listLessonTeacher(state) {
        return state.listLessonTeacher;
    },
    listUserGifts(state) {
        return state.user_gifts;
    },
    studentCount(state) {
        return state.student_count;
    },
    listTeachers(state) {
        return state.teachers;
    },
    teacherCount(state) {
        return state.teacher_count;
    },
    userInfo(state) {
        return state.user;
    },
    listAdmins(state) {
        return state.admin;
    },
    listCodeUsed(state) {
        return state.list_code;
    },
    listLessonProcess(state) {
        return state.lesson_process;
    }
};

const actions = {
    [GET_LIST_STUDENT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_STUDENT, payload).then(({data}) => {
                if (data.status) {
                    console.log("get list student action");
                    context.commit(SET_LIST_STUDENT, data);
                }
                resolve(data);
            });
        });
    },
    [GET_STUDENTS](context) {
        return new Promise(resolve => {
            ApiService.query(GET_STUDENTS).then(({data}) => {
                if (data.status) {
                    console.log("get list student action");
                    context.commit(SET_LIST_STUDENT, data);
                }
                resolve(data);
            });
        });
    },
    [GET_LIST_STUDENT_BY_COURSE](context,payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_STUDENT_BY_COURSE,payload).then(({data}) => {
                if (data.status) {
                    console.log("get list student action");
                    context.commit(SET_LIST_STUDENT, data);
                }
                resolve(data);
            });
        });
    },
    [GET_HISTORY_BOOK_LESSON](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_HISTORY_BOOK_LESSON, payload).then(({data}) => {
                if (data.status) {
                    console.log("get list student action");
                    context.commit(SET_HISTORY_BOOK_LESSON, data.data);
                }
                resolve(data);
            });
        });
    },
    [GET_HISTORY_BOOK_LESSON_TEACHER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_HISTORY_BOOK_LESSON_TEACHER, payload).then(({data}) => {
                if (data.status) {
                    console.log("get list student action");
                    context.commit(SET_HISTORY_BOOK_LESSON_TEACHER, data.data);
                }
                resolve(data);
            });
        });
    },
    [GET_LIST_USER_GIFT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_USER_GIFT, payload).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_USER_GIFT, data);
                }
                resolve(data);
            });
        });
    },
    [GET_LESSON_PROCESS](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LESSON_PROCESS, payload).then(({data}) => {
                if (data.status) {
                    console.log("get list student action");
                    context.commit(SET_LIST_LESSON_PROCESS, data);
                }
                resolve(data);
            });
        });
    },
    [GET_USER_INFO](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_USER_INFO, payload).then(({data}) => {
                if (data.status) {
                    context.commit(SET_USER_INFO, data);
                }
                resolve(data);
            });
        });
    },
    [GET_STUDENT_SEARCH_RESULT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_STUDENT_SEARCH_RESULT, payload).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_STUDENT, data);
                }
                resolve(data);
            });
        });
    },
    [COUNT_STUDENT](context) {
        return new Promise(resolve => {
            ApiService.get(COUNT_STUDENT).then(({data}) => {
                if (data.status) {
                    context.commit(SET_COUNT_STUDENT, data);
                }
                resolve(data);
            });
        });
    },
    [GET_TEACHER_SEARCH_RESULT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_TEACHER_SEARCH_RESULT, payload).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_TEACHER, data);
                }
                resolve(data);
            });
        });
    },
    [GET_LIST_TEACHER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_TEACHER, payload).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_TEACHER, data);
                }
                resolve(data);
            });
        });
    },
    [UPDATE_USER_INFO](context, payload) {
        return new Promise(resolve => {
            ApiService.post(UPDATE_USER_INFO, payload).then(({data}) => {
                resolve(data);
            });
        });
    },
    [UPDATE_USER_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(UPDATE_USER_TYPE, payload).then(({data}) => {
                resolve(data);
            });
        });
    },
    [COUNT_TEACHER](context) {
        return new Promise(resolve => {
            ApiService.get(COUNT_TEACHER).then(({data}) => {
                if (data.status) {
                    context.commit(SET_COUNT_TEACHER, data);
                }
                resolve(data);
            });
        });
    },
    [GET_LIST_ADMIN](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_ADMIN, payload).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_ADMIN, data);
                }
                resolve(data);
            });
        });
    },
    [GET_LIST_AFFILIATE_CODE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_AFFILIATE_CODE, payload).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_AFFILIATE_CODE, data);
                }
                resolve(data);
            });
        });
    },
    [GET_ADMIN_SEARCH_RESULT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_ADMIN_SEARCH_RESULT, payload).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_ADMIN, data);
                }
                resolve(data);
            });
        });
    },
    [DELETE_USER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(DELETE_USER, payload).then(({data}) => {
                console.log("delete user success");
                resolve(data);
            });
        });
    },
    [BANNED_USER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(BANNED_USER, payload).then(({data}) => {
                console.log("banned user success");
                resolve(data);
            });
        });
    },
    [CREATE_AFFILIATE_CODE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(CREATE_AFFILIATE_CODE, payload).then(({data}) => {
                console.log("create affiliate code success");
                resolve(data);
            });
        });
    }
};

const mutations = {
    [SET_LIST_STUDENT](state, data) {
        state.students = data.data;
    },
    [SET_LIST_LESSON_PROCESS](state, data) {
        state.lesson_process = data.data;
    },
    [SET_COUNT_STUDENT](state, data) {
        state.student_count = data.data.count;
    },
    [SET_USER_INFO](state, data) {
        state.user = data.data;
    },
    [SET_LIST_TEACHER](state, data) {
        state.teachers = data.data;
    },
    [SET_COUNT_TEACHER](state, data) {
        state.teacher_count = data.data.count;
    },
    [SET_LIST_ADMIN](state, data) {
        state.admin = data.data;
    },
    [SET_LIST_AFFILIATE_CODE](state, data) {
        state.list_code = data.data;
    },
    [SET_LIST_USER_GIFT](state, data) {
        state.user_gifts = data.data;
    },
    [SET_HISTORY_BOOK_LESSON](state, data) {
        state.listLessonStudents = data.data;
    },
    [SET_HISTORY_BOOK_LESSON_TEACHER](state, data) {
        state.listLessonTeacher = data.data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
