import ApiService from "@/common/api.service";
// group question
export const GET_GROUP_QUESTION_DETAIL = "/group-question/detail";
export const SAVE_GROUP_QUESTION = "/group-question/save";
export const UPDATE_GROUP_QUESTION = "/group-question/update";
export const DELETE_GROUP_QUESTION = "/group-question/delete";
export const LIST_GROUP_QUESTION_BY_TYPE = "/group-question/list-by-type";
export const GET_GROUP_QUESTION_TYPE = "/group-question/type";

export const SET_GROUP_QUESTION_TYPE = "setGroupQuestionType";

export const SET_GROUP_QUESTION_LIST = "setGroupQuestionList";
export const SET_GROUP_QUESTION_DETAIL = "setGroupQuestionDetail";

export const GET_GROUP_QUESTION_LIST_BY_CONFIG = "/group-question/find-by-config";
export const SET_GROUP_QUESTION_LIST_BY_CONFIG = "setGroupQuestionListByConfig";

const state = {
    group_questions: [],
    group_question: {
        audio: {
            src: ""
        }
    },
    type: [],
    group_questions_by_config:[],
    group_detail : {}
}
// getters
const getters = {
    groupQuestionList(state) {
        return state.group_questions
    },
    groupQuestionDetail(state) {
        return state.group_question
    },
    groupQuestionType(state) {
        return state.type
    },
    groupQuestionListByConfig(state) {
        return state.group_questions_by_config
    },
    group_detail(state) {
        return state.group_detail
    }
}
// actions
const actions = {
    // group question
    async [GET_GROUP_QUESTION_DETAIL](context, payload) {
        return ApiService.query(GET_GROUP_QUESTION_DETAIL, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_GROUP_QUESTION_DETAIL, data.data);
                context.commit('getDetailGroupQuestion', data.data);
            }
        })
    },
    async [LIST_GROUP_QUESTION_BY_TYPE](context, payload) {
        return ApiService.query(LIST_GROUP_QUESTION_BY_TYPE, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_GROUP_QUESTION_LIST, data.data);
            }
        })
    },
    async [GET_GROUP_QUESTION_TYPE](context, payload) {
        return ApiService.query(GET_GROUP_QUESTION_TYPE, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_GROUP_QUESTION_TYPE, data.data);
            }
        })
    },
    async [GET_GROUP_QUESTION_LIST_BY_CONFIG](context, payload) {
        return ApiService.query(GET_GROUP_QUESTION_LIST_BY_CONFIG, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_GROUP_QUESTION_LIST_BY_CONFIG, data.data);
            }
        })
    },
    async [SAVE_GROUP_QUESTION](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(SAVE_GROUP_QUESTION, payload).then(({data}) => {
                if (data.status) {
                    resolve(data);
                }
            })
        });
    },
    async [UPDATE_GROUP_QUESTION](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(UPDATE_GROUP_QUESTION, payload).then(({data}) => {
                if (data.status) {
                    resolve(data);
                }
            })
        });
    },
    async [DELETE_GROUP_QUESTION](context, payload) {
        return ApiService.delete(DELETE_GROUP_QUESTION, payload)
    },
}
// mutations
const mutations = {
    // exam meta
    [SET_GROUP_QUESTION_LIST](state, data) {
        state.group_questions = data;
    },
    [SET_GROUP_QUESTION_DETAIL](state, data) {
        if (data) {
            state.group_question = data;
            if(!state.group_question.audio){
                state.group_question.audio = {
                    src :""
                }
            }
        } else {
            state.group_question = {
                audio: {
                    src: ""
                },
                group_question_type_id: 1,
                from: null,
                to: null,
            }
        }
    },
    [SET_GROUP_QUESTION_TYPE](state, data) {
        state.type = data;
    },
    [SET_GROUP_QUESTION_LIST_BY_CONFIG](state, data) {
        state.group_questions_by_config = data;
    },
    getDetailGroupQuestion(state, data) {
        console.log(data)
        state.group_detail = data;
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}
