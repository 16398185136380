var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"pa-0 ma-0",attrs:{"scrollable":"","max-width":"1000","light":""},on:{"click:outside":_vm.onClickOutside},model:{value:(_vm.showModel),callback:function ($$v) {_vm.showModel=$$v},expression:"showModel"}},[_c('v-card',{staticClass:"pa-0 ma-0"},[_c('ValidationObserver',{ref:"add_question"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("1. "+_vm._s(_vm.nameText))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hnr-input',{attrs:{"name":'name',"text":_vm.feedback.name,"label":_vm.nameText},model:{value:(_vm.feedback.name),callback:function ($$v) {_vm.$set(_vm.feedback, "name", $$v)},expression:"feedback.name"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("2. "+_vm._s(_vm.positionText))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hnr-input',{attrs:{"name":'position',"text":_vm.feedback.position,"label":_vm.positionText},model:{value:(_vm.feedback.position),callback:function ($$v) {_vm.$set(_vm.feedback, "position", $$v)},expression:"feedback.position"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("3. "+_vm._s(_vm.feedbackText))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hnr-textarea',{attrs:{"name":'title',"text":_vm.feedback.feedback,"label":_vm.feedbackText},model:{value:(_vm.feedback.feedback),callback:function ($$v) {_vm.$set(_vm.feedback, "feedback", $$v)},expression:"feedback.feedback"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("4. "+_vm._s(_vm.avatarText))]),_c('div',[_vm._v(" "+_vm._s(_vm.language.picture)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"pl-1",attrs:{"name":"image","prepend-icon":"mdi-camera","label":"Tải ảnh","accept":"image/png, image/jpeg, image/bmp, image/webp"},on:{"change":_vm.onChangeFileUpload,"click:clear":_vm.clearImage},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),(_vm.show)?_c('div',{attrs:{"id":"preview"}},[_c('v-img',{attrs:{"src":_vm.feedback.image_src}})],1):_c('span',{staticClass:"validate-error text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-btn',{attrs:{"color":"primary","type":"submit","form":"check-feedback"},on:{"click":_vm.saveFeedbackConfig}},[_vm._v("Lưu")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }