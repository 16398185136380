import ApiService from "@/common/api.service";

export const GET_LIST_BOOK = "/books/list";
export const GET_LIST_NOY_BUY_BOOK = "/books/list-not-buy";
export const GET_DETAIL_BOOK = "/books/detail";
export const SAVE_BOOK = "/books/save";
export const UPDATE_BOOK = "/books/update";
export const UPDATE_STATUS_BOOK = "/books/updateStatus";
export const UPDATE_NOT_BUY_BOOK = "/books/updateNotBuy";
export const DELETE_BOOK = "/books/delete";
export const UPLOAD_BOOK_IMAGE = "/books/image/upload";
export const UPLOAD_BOOK_MULTIPLE_IMAGE = "/books/multiple-image/upload";


export const SET_LIST_BOOK = "setListBook";
export const SET_LIST_NOY_BUY_BOOK = "setListNotBuyBook";
export const SET_DETAIL_BOOK = "setDetailBook";


const state = {
    books: [],
    booksNotBuy: [],
    book: {},
};

const getters = {
    books(state) {
        return state.books;
    },
    booksNotBuy(state) {
        return state.booksNotBuy;
    },
    bookDetail(state) {
        return state.book;
    },
};

const actions = {
    [GET_LIST_BOOK](context) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_BOOK).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_BOOK, data);
                }
                resolve(data);
            });
        });
    },
    [GET_LIST_NOY_BUY_BOOK](context) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_NOY_BUY_BOOK).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_NOY_BUY_BOOK, data);
                }
                resolve(data);
            });
        });
    },
    [GET_DETAIL_BOOK](context, id) {
        return new Promise(resolve => {
            ApiService.query(`${GET_DETAIL_BOOK}/${id}`).then(({data}) => {
                if (data.status) {
                    context.commit(SET_DETAIL_BOOK, data);
                }
                resolve(data);
            });
        });
    },
    [UPLOAD_BOOK_IMAGE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPLOAD_BOOK_IMAGE, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [UPLOAD_BOOK_MULTIPLE_IMAGE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPLOAD_BOOK_MULTIPLE_IMAGE, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [SAVE_BOOK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_BOOK, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [UPDATE_BOOK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_BOOK, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [UPDATE_STATUS_BOOK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_STATUS_BOOK, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [UPDATE_NOT_BUY_BOOK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_NOT_BUY_BOOK, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [DELETE_BOOK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(DELETE_BOOK, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
};

const mutations = {
    [SET_LIST_BOOK](state, data) {
        state.books = data.data;
    },
    [SET_LIST_NOY_BUY_BOOK](state, data) {
        state.booksNotBuy = data.data;
    },
    [SET_DETAIL_BOOK](state, data) {
        state.book = data.data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
