import ApiService from "@/common/api.service";

export const GET_LIST_COMBO = "/combo-course/list";
export const GET_DETAIL_COMBO = "/combo-course/detail";
export const SAVE_COMBO = "/combo-course/save";
export const UPDATE_COMBO = "/combo-course/update";
export const DELETE_COMBO = "/combo-course/delete";


export const SET_LIST_COMBO = "setListCombo";
export const SET_DETAIL_COMBO = "setDetailCombo";


const state = {
    combos: [],
    combo: {},
};

const getters = {
    combos(state) {
        return state.combos;
    },
    comboDetail(state) {
        return state.combo;
    },
};

const actions = {
    [GET_LIST_COMBO](context) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_COMBO).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_COMBO, data);
                }
                resolve(data);
            });
        });
    },
    [GET_DETAIL_COMBO](context, id) {
        return new Promise(resolve => {
            ApiService.query(`${GET_DETAIL_COMBO}/${id}`).then(({data}) => {
                if (data.status) {
                    context.commit(SET_DETAIL_COMBO, data);
                }
                resolve(data);
            });
        });
    },
    [SAVE_COMBO](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_COMBO, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [UPDATE_COMBO](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_COMBO, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [DELETE_COMBO](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(DELETE_COMBO, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
};

const mutations = {
    [SET_LIST_COMBO](state, data) {
        state.combos = data.data;
    },
    [SET_DETAIL_COMBO](state, data) {
        state.combo = data.data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
