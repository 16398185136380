import ApiService from "@/common/api.service";
import {DELETE_BOOK} from "./book.module";

// action types
export const SAVE_COURSE = "/course/save";
export const UPDATE_COURSE = "/course/update";
export const UPLOAD_COURSE_IMAGE = "/course/image/upload";
export const GET_LIST_COURSE = "/course/list";
export const GET_COURSE_DETAIL = "/course/detail";
export const GET_PURCHASE_COURSE_LIST = "course/purchase";
export const DELETE_COURSE = "/course/delete";
export const CHANGE_EXPIRED_DATE = "/change_expired_date";
// mutation types
export const SET_LIST_COURSE = "setListCourse";
export const SET_COURSE_DETAIL = "setCourseDetail";
export const SET_DEFAULT_COURSE = "setDefaultCourse";

// export const UPDATE_PRODUCT = "updateProduct";
const state = {
  courses: [],
  purchaseCourses: [],
  detailCourse: {
    title: "",
    price: "",
    description: "",
    introduction: "",
    number_month_expired: "",
    slug: "",
    meta: "",
    status: 0,
    is_recommend: 0,
    lesson_number: 0,
    images: {
      src: ""
    },
    responsive_image:{
      src : ""
    }
  },
};
const getters = {
  listCourse(state) {
    return state.courses;
  },
  detailCourse(state) {
    return state.detailCourse;
  },
  purchaseCourseList(state) {
    return state.purchaseCourses;
  }
};

const actions = {
  [GET_LIST_COURSE](context) {
    return new Promise(resolve => {
      ApiService.get(GET_LIST_COURSE)
        .then(({ data }) => {
          if (data.status) {
            context.commit(SET_LIST_COURSE, data);
          }
          resolve(data);
        })
    });
  },
  [DELETE_COURSE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(DELETE_COURSE, payload)
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            console.log(err)
            reject(err);
          });
    });
  },
  [CHANGE_EXPIRED_DATE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(CHANGE_EXPIRED_DATE, payload)
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            console.log(err)
            reject(err);
          });
    });
  },
  [GET_COURSE_DETAIL](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_COURSE_DETAIL, payload)
        .then(({ data }) => {
          if (payload === undefined) {
            context.commit(SET_DEFAULT_COURSE);
            return;
          }
          if (data.status) {
            context.commit(SET_COURSE_DETAIL, data.data);
          }
          resolve(data);
        })
    });
  },
  [UPLOAD_COURSE_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPLOAD_COURSE_IMAGE, payload)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          console.log(err)
          reject(err);
        });
    });
  },
  [SAVE_COURSE](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(SAVE_COURSE, payload)
        .then(({ data }) => {
          resolve(data)
        })

    });
  },
  [UPDATE_COURSE](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(UPDATE_COURSE, payload)
        .then(({ data }) => {
          resolve(data)
        })

    });
  },
  [GET_PURCHASE_COURSE_LIST]({commit},payload) {
    return new Promise(() => {
      ApiService.query(GET_PURCHASE_COURSE_LIST, payload)
          .then(({ data }) => {
            console.log(data,'data')
            commit('getPurchaseCourseListSuccess', data.data)
            return data.data;
          })

    });
  },
};

const mutations = {
  [SET_LIST_COURSE](state, data) {
    state.courses = data.data;
  },
  [SET_COURSE_DETAIL](state, data) {
    if(data.hasOwnProperty('responsive_image') && !data.responsive_image){
      data.responsive_image = {};
      data.responsive_image = Object.assign({},{
        src: ""
      })
    }
    state.detailCourse = data;

  },
  [SET_DEFAULT_COURSE](state) {
      state.detailCourse = {
        title: "",
        price: "",
        description: "",
        introduction: "",
        number_month_expired: "",
        slug: "",
        meta: "",
        status: 0,
        is_recommend: 0,
        lesson_number: 0,
        images: {
          src: ""
        },
        responsive_image:{
          src : ""
        }
      }
    },
  getPurchaseCourseListSuccess(state, purchase) {
    state.purchaseCourses = purchase
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
