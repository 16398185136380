var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"add_question"},[_c('v-container',[_c('v-row',{staticClass:"align-start justify-start"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"text-body-1"},[_c('b',[_vm._v(_vm._s(_vm.linkFace))])])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"facebook_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hnr-input',{attrs:{"name":'facebook_link',"text":_vm.footer.facebook,"label":'Nhập đường dẫn trang Facebook'},on:{"input":_vm.updateFooterConf},model:{value:(_vm.footer.facebook),callback:function ($$v) {_vm.$set(_vm.footer, "facebook", $$v)},expression:"footer.facebook"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"align-start justify-start pa-0 footer"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"text-body-1"},[_c('b',[_vm._v(_vm._s(_vm.linkYoutobe))])])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"youtube_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hnr-input',{attrs:{"name":'youtube_link',"text":_vm.footer.youtube,"label":'Nhập đường dẫn trang youtube'},on:{"input":_vm.updateFooterConf},model:{value:(_vm.footer.youtube),callback:function ($$v) {_vm.$set(_vm.footer, "youtube", $$v)},expression:"footer.youtube"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"align-start justify-start pa-0 footer"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"text-body-1"},[_c('b',[_vm._v(_vm._s(_vm.linkInstagram))])])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"instagram_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hnr-input',{attrs:{"name":'instagram_link',"text":_vm.footer.instagram,"label":'Nhập đường dẫn trang Instagram'},on:{"input":_vm.updateFooterConf},model:{value:(_vm.footer.instagram),callback:function ($$v) {_vm.$set(_vm.footer, "instagram", $$v)},expression:"footer.instagram"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"align-start justify-start pa-0 footer"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"text-body-1"},[_c('b',[_vm._v(_vm._s(_vm.linkPrinterest))])])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"printerest_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hnr-input',{attrs:{"name":'printerest_link',"text":_vm.footer.printerest,"label":'Nhập đường dẫn trang Printerest'},on:{"input":_vm.updateFooterConf},model:{value:(_vm.footer.printerest),callback:function ($$v) {_vm.$set(_vm.footer, "printerest", $$v)},expression:"footer.printerest"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"align-start justify-start pa-0 footer"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"text-body-1"},[_c('b',[_vm._v(_vm._s(_vm.linkZalo))])])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"zalo_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hnr-input',{attrs:{"name":'zalo_link',"text":_vm.footer.zalo,"label":'Nhập đường dẫn trang Zalo'},on:{"input":_vm.updateFooterConf},model:{value:(_vm.footer.zalo),callback:function ($$v) {_vm.$set(_vm.footer, "zalo", $$v)},expression:"footer.zalo"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"align-start justify-start pa-0 footer"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"text-body-1"},[_c('b',[_vm._v(_vm._s(_vm.titleText))])])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('hnr-input',{attrs:{"text":_vm.footer.title,"label":'Title'},on:{"input":_vm.updateFooterConf},model:{value:(_vm.footer.title),callback:function ($$v) {_vm.$set(_vm.footer, "title", $$v)},expression:"footer.title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"align-start justify-start pa-0 footer"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"text-body-1"},[_c('b',[_vm._v(_vm._s(_vm.descriptionText))])])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"descriptionText","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":_vm.descriptionText,"dense":"","outlined":"","placeholder":"Description"},model:{value:(_vm.footer.description),callback:function ($$v) {_vm.$set(_vm.footer, "description", $$v)},expression:"footer.description"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-btn',{attrs:{"color":"primary","type":"submit","form":"check-footer"},on:{"click":_vm.saveFooterConfig}},[_vm._v(_vm._s(_vm.save))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }