import ApiService from "@/common/api.service";

export const GET_LIST_FEEDBACK = "/feedbacks";


export const SET_LIST_FEEDBACK = "setListFeedback";


const state = {
    feedbacks: [],
};

const getters = {
    feedbacks(state) {
        return state.feedbacks;
    },
};

const actions = {
    [GET_LIST_FEEDBACK](context) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_FEEDBACK).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_FEEDBACK, data);
                }
                resolve(data);
            });
        });
    },
};

const mutations = {
    [SET_LIST_FEEDBACK](state, data) {
        state.feedbacks = data.data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
