// China
export const locale = {
  TRANSLATOR: {
    SELECT: "Chọn ngôn ngữ "
  },
  MENU: {
    NEW: "Tin tức",
    ACTIONS: "Hành động",
    CREATE_POST: "Taọ một bài viết mới",
    PAGES: "Số trang",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard"
  },
  AUTH: {
    GENERAL: {
      OR: "hoăc",
      SUBMIT_BUTTON: "Gửi đi",
      NO_ACCOUNT: "Không có tài khoản？",
      SIGNUP_BUTTON: "Đăng ký",
      FORGOT_BUTTON: "Quên mật khẩu",
      BACK_BUTTON: "Trở lại",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "liên hệ"
    },
    LOGIN: {
      TITLE: "创建帐号",
      BUTTON: "签到"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "用戶名"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Số lượng bản ghi đã chọn: ",
      ALL: "All",
      SUSPENDED: "Bị đình chỉ",
      ACTIVE: "Hoạt động",
      FILTER: "Bộ lọc",
      BY_STATUS: "theo Trạng thái",
      BY_TYPE: "theo loại",
      BUSINESS: "Kinh doanh",
      INDIVIDUAL: "Cá nhân",
      SEARCH: "Tìm kiếm",
      IN_ALL_FIELDS: "trong tất cả các lĩnh vực"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "顾客",
      CUSTOMERS_LIST: "客户名单",
      NEW_CUSTOMER: "khách hàng mới",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Xóa khách hàng",
        DESCRIPTION: "Bạn có chắc chắn xóa vĩnh viễn khách hàng này không?",
        WAIT_DESCRIPTION: "Khách hàng đang xóa",
        MESSAGE: "Khách hàng đã bị xóa"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Khách hàng đã Xóa",
        DESCRIPTION: "Bạn có chắc chắn xóa vĩnh viễn những khách hàng đã chọn không?",
        WAIT_DESCRIPTION: "Khách hàng đang xóa ...",
        MESSAGE: "Khách hàng được chọn đã bị xóa"
      },
      UPDATE_STATUS: {
        TITLE: "Trạng thái đã được cập nhật cho những khách hàng được chọn",
        MESSAGE: "Trạng thái khách hàng được chọn đã được cập nhật thành công"
      },
      EDIT: {
        UPDATE_MESSAGE: "Khách hàng đã được cập nhật",
        ADD_MESSAGE: "Khách hàng đã được tạo"
      }
    }
  }
};
