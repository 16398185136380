<template>
  <v-card class="course-box-1" color="#F1EFF1" light>
    <v-img :src="course.images.src" aspect-ratio="1.7" height="50%"></v-img>
    <router-link :title="course.title" :to="{ path: path, query: { course_slug: course.slug,viewType:1 }}">
      <v-card-subtitle class="text-center" primary-title>
        <div class="max-line">
          {{ course.title }}
        </div>
      </v-card-subtitle>
    </router-link>
    <v-card-actions>
      <v-list-item>
        <v-row>
          <v-col cols="9">
            <div>
              Doanh thu: <span class="subheading font-weight-bold">{{ formatPrice(course.revenue) }}</span>
            </div>
            <div>
              Đăng ký: <span class="subheading font-weight-bold">{{ course.user_register }}</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-icon @click="deleteItem(course.id)">
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props:
      {
        course: {
          type: Object,
          default: {}
        },
        path: {
          type: String,
          default: '/course'
        }
      },
  methods: {
    formatPrice(price) {
      let data_price = parseFloat(price)
      if (data_price < 0) {
        return 0
      }
      return new Intl.NumberFormat('de-DE').format(data_price)
    },
    deleteItem(id) {
      this.$emit('deleteItem', id)
    }
  }
};
</script>

<style>
.course-box-1 {
  width: 260px;
  height: 230px;
  border: 1px solid #36a635 !important;
  border-radius: 30px !important;
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
}

.max-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme--light.v-card .v-card__subtitle {
  color: black !important;
}

.v-card__subtitle {
  font-weight: bold !important;
  font-size: 1rem !important;
  padding: 12px !important;
}
</style>
