import ApiService from "@/common/api.service";

export const UPLOAD_EXERCISE_MATERIAL = "/exercise/material/upload";
export const UPDATE_EXERCISE_MATERIAL = "/exercise/material/update";
// action types
export const GET_EXERCISE_DETAIL = "/exercise/detail";
export const SAVE_EXERCISE = "/exercise/save";
export const UPDATE_EXERCISE = "/exercise/update";

// reading material
export const SAVE_READING_MATERIAL = "/exercise/reading-material/save";
export const GET_READING_MATERIAL_DETAIL = "/exercise/reading-material/detail";
export const GET_LISTEN_MATERIAL_DETAIL = "/exercise/listen-material/detail";
export const SAVE_EXERCISE_CONTENT = "/exercise/content/save";
export const UPDATE_EXERCISE_CONTENT = "/exercise/content/update";
export const GET_EXERCISE_CONTENT = "/exercise/content/detail";
export const GET_EXERCISE_LIST_READING = "/exercise/content/list-reading";
export const GET_EXERCISE_LIST_LISTING = "/exercise/content/list-listening";
export const DELETE_EXERCISE_READING = "/exercise/delete/reading";
export const DELETE_EXERCISE_LISTENING = "/exercise/delete/Listening";
export const DELETE_EXERCISE_CONTENT = "/exercise/content/delete";
export const DELETE_EXERCISE_CONTENT_READING = "/exercise/content-reading/delete";
export const SAVE_EXERCISE_CHOOSE_ANSWER = "/exercise/choose-answer/save";
export const UPDATE_EXERCISE_CHOOSE_ANSWER = "/exercise/choose-answer/update";

// filled word
export const GET_FILLED_WORD_LIST = "/exercise/filledword/list";
export const SAVE_FILLED_WORD = "/exercise/filledword/save";
export const DELETE_FILLED_WORD = "/exercise/filledword/delete";

// question
export const GET_QUESTION_DETAIL = "/exercise/question/detail";
// export const GET_LISTENING_QUESTION_DETAIL = "/exercise/question/detail";
export const GET_TRANSLATE_QUESTION_DETAIL = "/exercise/translate/question/detail";

//translate
export const GET_TRANSLATE_DETAIL = "/exercise/translate/question/detail";
export const SET_TRANSLATE_DETAIL = "setTranslateDetail";

export const GET_TRANSLATE_ANSWER_DETAIL = "/exercise/translate/answer/detail";
export const SET_TRANSLATE_ANSWER_DETAIL = "setTranslateAnswerDetail";

//answer
export const GET_ANSWER_DETAIL = "/exercise/answer/detail";
export const SET_ANSWER_DETAIL = "setAnswerDetail";

// mutation types
export const SET_EXERCISE_DETAIL = "setExerciseDetail";
export const SET_EXERCISE_CONTENT = "setExerciseContent";
export const SET_EXERCISE_LIST_READING = "setExerciseListReading";
export const SET_EXERCISE_LIST_LISTING = "setExerciseListListing";
export const SET_FILLED_WORD_LIST = "setFilledWordList";
export const SET_FILLED_WORD_LIST_V2 = "setFilledWordList";

export const SET_READING_MATERIAL = "setReadingMaterial";
export const SET_READING_MATERIAL_DETAIL = "setReadingMaterialDetail";
export const SET_LISTEN_MATERIAL_DETAIL = "setListenMaterialDetail";
export const SET_QUESTION_DETAIL = "setQuestionDetail";
export const SET_TRANSLATE_QUESTION_DETAIL = "setTranslateQuestionDetail";
export const SET_LISTENING_QUESTION_DETAIL = "setListeningQuestionDetail";

export const GET_QUESTION_LIST_DETAIL = "/exercise/questionList/detail";
export const SET_QUESTION_LIST_DETAIL = "setQuestionAnswerList";

export const GET_ANSWER_LIST_DETAIL = "/exercise/answer/list";
export const SET_ANSWER_LIST_DETAIL = "setAnswerList";
export const UPDATE_IS_ANSWER = "/exercise/answer/update";
export const DELETE_CHOOSE_ANSWER = "/exercise/choose-answer/delete";

const state = {
    exercise: {},
    saveread: {},
    flashcards: [],
    questions: [
        {
            id: -1,
            content: "",
            answer: [
                {
                    id: -1,
                    content: "",
                    check: -1,
                    exercised_question_id: -1
                }],
            filled_dialog: {
                missing_sentence: "",
                complete_sentence: "",
                exercised_question_id: -1
            }
        }
    ],
    question: {
        id: -1,
        content: "",
        answer: {
            id: -1,
            content: "",
            exercised_question_id: -1
        },
    },
    answers: [
        {
            id: -1,
            content: "",
            answer: {
                id: -1,
                content: "",
                exercised_question_id: -1
            },
            translate: {
                id: -1,
                content: "",
                exercised_question_id: -1,
                exercised_answer_id: -1
            },
        }
    ],
    translate: {
        id: -1,
        content: "",
        exercised_question_id: -1,
        exercised_answer_id: -1,
        isAnswer: false,
    },
    answer: {
        id: -1,
        content: "",
        check: -1,
        exercised_translate_id: -1,
    },
    listeningQuestion: {},
    material: {
        id: -1,
        subtitle: "",
        translated_subtitle: "",
        audio: {
            src: ""
        }
    },
    wordLinks: [],
    wordPairs: [],
    filledWords: [],
    readingMaterial: {},
    listenMaterial: {},
    listReading: [],
    listListing: [],
    type: 0
};
// getters
const getters = {
    flashcardList(state) {
        return state.flashcards
    },
    listenMaterial(state) {
        return state.listenMaterial
    },
    listReading(state) {
        return state.listReading
    },
    listListing(state) {
        return state.listListing
    },
    exerciseDetail(state) {
        return state.exercise
    },
    readResponse(state) {
        return state.saveread
    },
    setReadingMaterialDetail(state) {
        return state.readingMaterial
    },
    questionList(state) {
        return state.questions
    },
    readingMaterial(state) {
        return state.readingMaterial;
    },
    materialDetail(state) {
        return state.material
    },
    questionDetail(state) {
        return state.question
    },
    translateDetail(state) {
        return state.translate
    },
    translateAnswerDetail(state) {
        return state.translate
    },
    translateQuestionDetail(state) {
        return state.translate
    },
    listeningQuestionDetail(state) {
        return state.listeningQuestion
    },
    wordLinkList(state) {
        return state.wordLinks
    },
    wordPairList(state) {
        return state.wordPairs
    },
    filledWordList(state) {
        return state.filledWords
    },
    answerDetail(state) {
        return state.answer
    },
    answerList(state) {
        return state.answers
    },
}
// actions
const actions = {
    // exercise
    async [GET_EXERCISE_DETAIL](context, payload) {
        return ApiService.query(GET_EXERCISE_DETAIL, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_EXERCISE_DETAIL, data);
                }
            })
    },

    async [GET_READING_MATERIAL_DETAIL](context, payload) {
        return ApiService.query(GET_READING_MATERIAL_DETAIL, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_READING_MATERIAL_DETAIL, data);
                }
            })
    },
    async [GET_LISTEN_MATERIAL_DETAIL](context, payload) {
        return ApiService.query(GET_LISTEN_MATERIAL_DETAIL, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_LISTEN_MATERIAL_DETAIL, data);
                }
            })
    },
    async [GET_QUESTION_LIST_DETAIL](context, payload) {
        return ApiService.query(GET_QUESTION_LIST_DETAIL, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_QUESTION_LIST_DETAIL, data);
                }
            })
    },
    async [UPDATE_EXERCISE](context, payload) {
        return ApiService.post(UPDATE_EXERCISE, payload);
    },
    async [SAVE_EXERCISE](context, payload) {
        return ApiService.post(SAVE_EXERCISE, payload);
    },

    // exercise content
    async [GET_EXERCISE_CONTENT](context, payload) {
        payload.index = payload.index ? payload.index : null
        return ApiService.query(GET_EXERCISE_CONTENT, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_EXERCISE_CONTENT, data);
                }
            })
    },
    async [GET_EXERCISE_LIST_READING](context, payload) {
        return ApiService.query(GET_EXERCISE_LIST_READING, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_EXERCISE_LIST_READING, data);
                }
            })
    },
    async [GET_EXERCISE_LIST_LISTING](context, payload) {
        return ApiService.query(GET_EXERCISE_LIST_LISTING, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_EXERCISE_LIST_LISTING, data);
                }
            })
    },
    async [SAVE_EXERCISE_CONTENT](context, payload) {
        return ApiService.post(SAVE_EXERCISE_CONTENT, payload)
    },
    async [UPDATE_EXERCISE_CONTENT](context, payload) {
        return ApiService.post(UPDATE_EXERCISE_CONTENT, payload)
    },
    async [SAVE_EXERCISE_CHOOSE_ANSWER](context, payload) {
        return ApiService.post(SAVE_EXERCISE_CHOOSE_ANSWER, payload)
    },
    async [UPDATE_EXERCISE_CHOOSE_ANSWER](context, payload) {
        return ApiService.post(UPDATE_EXERCISE_CHOOSE_ANSWER, payload)
    },
    async [DELETE_EXERCISE_CONTENT](context, payload) {
        return ApiService.post(DELETE_EXERCISE_CONTENT, payload)
    },
    async [DELETE_EXERCISE_CONTENT_READING](context, payload) {
        return ApiService.post(DELETE_EXERCISE_CONTENT_READING, payload)
    },
    // filled word
    async [GET_FILLED_WORD_LIST](context, payload) {
        return ApiService.query(GET_FILLED_WORD_LIST, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_FILLED_WORD_LIST, data);
                }
            })
    },
    async [SAVE_FILLED_WORD](context, payload) {
        return ApiService.post(SAVE_FILLED_WORD, payload)
    },
    async [DELETE_FILLED_WORD](context, payload) {
        return ApiService.post(DELETE_FILLED_WORD, payload)
    },
    async [DELETE_EXERCISE_READING](context, payload) {
        return ApiService.post(DELETE_EXERCISE_READING, payload)
    },
    async [DELETE_EXERCISE_LISTENING](context, payload) {
        return ApiService.post(DELETE_EXERCISE_LISTENING, payload)
    },
    // question
    async [GET_QUESTION_DETAIL](context, payload) {
        return ApiService.query(GET_QUESTION_DETAIL, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_QUESTION_DETAIL, data);
                }
            })
    },

    // translate
    async [GET_TRANSLATE_QUESTION_DETAIL](context, payload) {
        return ApiService.query(GET_TRANSLATE_QUESTION_DETAIL, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_TRANSLATE_QUESTION_DETAIL, data);
                }
            })
    },

    async [GET_TRANSLATE_DETAIL](context, payload) {
        return ApiService.query(GET_TRANSLATE_DETAIL, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_TRANSLATE_DETAIL, data);
                }
            })
    },
    async [GET_TRANSLATE_ANSWER_DETAIL](context, payload) {
        return ApiService.query(GET_TRANSLATE_ANSWER_DETAIL, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_TRANSLATE_ANSWER_DETAIL, data);
                }
            })
    },
    // async [GET_LISTENING_QUESTION_DETAIL](context, payload) {
    //   return ApiService.query(GET_LISTENING_QUESTION_DETAIL, payload)
    //     .then(({ data }) => {
    //       if (data.status) {
    //         context.commit(SET_LISTENING_QUESTION_DETAIL, data);
    //       }
    //     })
    // },
    // reading material

    // upload material
    async [UPLOAD_EXERCISE_MATERIAL](context, payload) {
        return ApiService.post(UPLOAD_EXERCISE_MATERIAL, payload)
    },
    async [UPDATE_EXERCISE_MATERIAL](context, payload) {
        return ApiService.post(UPDATE_EXERCISE_MATERIAL, payload)
    },
    async [SAVE_READING_MATERIAL](context, payload) {
        return ApiService.post(SAVE_READING_MATERIAL, payload)
            .then(({data}) => {
                context.commit(SAVE_READING_MATERIAL, data);
            })
    },
    //ANSWER
    async [GET_ANSWER_LIST_DETAIL](context, payload) {
        return ApiService.query(GET_ANSWER_LIST_DETAIL, payload)
            .then(({data}) => {
                if (data.status) {
                    context.commit(SET_ANSWER_LIST_DETAIL, data);
                }
            })
    },
    async [GET_ANSWER_DETAIL](context, payload) {
        return ApiService.query(GET_ANSWER_DETAIL, payload)
            .then(({data}) => {
                console.log(data);
                if (data.status) {
                    context.commit(SET_ANSWER_DETAIL, data);
                }
            })
    },
    async [UPDATE_IS_ANSWER](context, payload) {
        return ApiService.post(UPDATE_IS_ANSWER, payload)
    },
    async [DELETE_CHOOSE_ANSWER](context, payload) {
        return ApiService.post(DELETE_CHOOSE_ANSWER, payload)
    },
}
// mutations
const mutations = {
    [SET_EXERCISE_DETAIL](state, data) {
        state.exercise = data.data;
    },
    [SAVE_READING_MATERIAL](state, data) {
        state.saveread = data;
    },

    [SET_FILLED_WORD_LIST](state, data) {
        state.filledWords = data.data;
    },
    [SET_FILLED_WORD_LIST_V2](state, data) {
        state.filledWords = data.data;
    },
    [SET_QUESTION_LIST_DETAIL](state, data) {
        state.questions = data.data;
    },
    [SET_READING_MATERIAL](state, data) {
        state.readingMaterialDetail = data.data;
    },
    [SET_TRANSLATE_QUESTION_DETAIL](state, data) {
        if (data.data != null) {
            state.translate = data.data;
        } else {
            state.translate = {
                id: -1,
                content: "",
                exercised_answer_id: -1,
            };
        }
    },
    [SET_TRANSLATE_DETAIL](state, data) {
        if (data.data != null) {
            state.translate = data.data;
        } else {
            state.translate = {
                id: -1,
                content: "",
                exercised_answer_id: -1,
                isAnswer: false,
            };
        }
    },
    [SET_TRANSLATE_ANSWER_DETAIL](state, data) {
        if (data.data != null) {
            state.translate = data.data;
        } else {
            state.translate = {
                id: -1,
                content: "",
                exercised_answer_id: -1,
                isAnswer: false,
            };
        }
    },
    [SET_QUESTION_DETAIL](state, data) {
        if (data.data != null) {
            state.question = data.data;
        } else {
            state.question = {
                id: -1,
                question_type: 1,
                content: "",
                answers: [
                    {
                        id: -1,
                        content: "",
                        file: null,
                        url: null,
                        description: '',
                        check: 0,
                        exercised_question_id: -1
                    }],
                filled_dialog: {
                    missing_sentence: "",
                    complete_sentence: "",
                    exercised_question_id: -1
                }
            };
        }
    },
    [SET_READING_MATERIAL_DETAIL](state, data) {
        state.readingMaterial = data.data;
    },
    [SET_LISTEN_MATERIAL_DETAIL](state, data) {
        state.listenMaterial = data.data;
    },
    [SET_EXERCISE_LIST_READING](state, data) {
        state.listReading = data.data;
    },
    [SET_EXERCISE_LIST_LISTING](state, data) {
        state.listListing = data.data;
    },
    [SET_LISTENING_QUESTION_DETAIL](state, data) {
        if (data.data != null) {
            state.listeningQuestion = data.data;
        } else {
            // init data
            state.listeningQuestion = {
                id: -1,
                content: "",
                answers: [
                    {
                        id: -1,
                        content: "",
                        check: -1,
                        exercised_question_id: -1
                    }],
                filled_dialog: {
                    missing_sentence: "",
                    complete_sentence: "",
                    exercised_question_id: -1
                }
            };
            // shalow copy array
        }
    },
    [SET_EXERCISE_CONTENT](state, data) {
        if (data.data == null) {
            return;
        }
        let exercise = data.data;
        if (data.data.exercise_type == 1) {
            state.flashcards = exercise.flashcards;
        }
        if (exercise.exercise_type == 21 || exercise.exercise_type == 22 ||
            exercise.exercise_type == 23 || exercise.exercise_type == 24 ||
            exercise.exercise_type == 7 || exercise.exercise_type == 51 || exercise.exercise_type == 52 ||
            exercise.exercise_type == 25 || exercise.exercise_type == 27) {
            state.questions = exercise.questions;
        }
        if (data.data.exercise_type == 31) {
            state.wordLinks = exercise.words;
        }
        if (data.data.exercise_type == 32) {
            state.wordPairs = exercise.words;
        }
        if (data.data.exercise_type == 4) {
            if (exercise.material !== null) {
                state.material = exercise.material;
            }
            if (exercise.questions !== null) {
                state.questions = exercise.questions;
            }
        }
        if (data.data.exercise_type == 5 || exercise.exercise_type == 41 || exercise.exercise_type == 42) {
            if (exercise.material !== null) {
                state.material = exercise.material;
            } else {
                state.material = {
                    id: -1,
                    subtitle: "",
                    translated_subtitle: "",
                    audio: {
                        src: ""
                    }
                }
            }
            if (exercise.questions !== null) {
                state.questions = exercise.questions;
            }
        }
    },
    [SET_ANSWER_LIST_DETAIL](state, data) {
        state.answers = data.data;
    },
    [SET_ANSWER_DETAIL](state, data) {
        if (data.data != null) {
            state.answer = data.data;
        } else {
            state.answer = {
                id: -1,
                content: "",
                check: -1,
                exercised_question_id: -1
            };
        }
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}
