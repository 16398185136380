import ApiService from "@/common/api.service";

// action types
export const UPLOAD_IMAGE = "/image/upload";
export const UPLOAD_VIDEO = "/video/upload";

// mutation types
export const SET_IMAGE_URL = "setImageUrl";
export const SET_VIDEO_URL = "setVideoUrl";

const state = {
  image_url: "",
  video_url: ""
};
const getters = {
  imageUrl(state) {
    return state.image_url;
  },
  videoUrl(state) {
    return state.video_url;
  }
};

const actions = {
  [UPLOAD_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPLOAD_IMAGE, payload)
        .then(data => {
          if (data.data.status){
            context.commit(SET_IMAGE_URL, data.data);
          }
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [UPLOAD_VIDEO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPLOAD_VIDEO, payload)
        .then(data => {
          if(data.data.status){
            context.commit(SET_VIDEO_URL, data.data);
          }
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_IMAGE_URL](state, data) {
    state.image_url = data.data;
  },
  [SET_VIDEO_URL](state, data) {
    state.video_url = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
