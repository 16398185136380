<template>
  <v-container>
    <div class="alert alert-light alert-elevate" role="alert">
      <div class="alert-icon alert-icon-top">
        <i class="flaticon-warning kt-font-brand"></i>
      </div>
      <div class="alert-text">
        <p v-html="note"></p>
      </div>
    </div>
    <v-form @submit.prevent="handleSubmit()" method="post" id="setting-form">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="item in items" :key="item.id">{{ item.tab }}</v-tab>
      </v-tabs>
      <v-tabs-items class="tab-content" v-model="tabIndex">
        <v-tab-item active>
          <list-top-slider-config></list-top-slider-config>
        </v-tab-item>
        <v-tab-item>
          <intro-teacher-config></intro-teacher-config>
        </v-tab-item>

        <v-tab-item>
          <benefit-config></benefit-config>
        </v-tab-item>

        <v-tab-item>
          <CourseConfig/>
        </v-tab-item>

        <v-tab-item>
          <footer-config></footer-config>
        </v-tab-item>
        <v-tab-item>
          <ListFeedbackUserConfig/>
        </v-tab-item>
        <v-tab-item>
          <bannerExam/>
        </v-tab-item>
      </v-tabs-items>
      <div class="kt-portlet__foot">
        <div class="kt-form__actions">
          <div class="row justify-center">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <v-btn type="submit" class="btn btn-secondary mr-2">
                <i class="la la-eye"></i>
                Preview
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {GET_HOME_PAGE_CONF} from "@/store/setting.module";

import ListTopSliderConfig from "@/components/setting-model/ListTopSliderConfig";
import CourseConfig from "@/components/setting-model/CourseConfig";
import FooterConfig from "@/components/setting-model/FooterConfig";
import ListFeedbackUserConfig from "@/components/setting-model/ListFeedbackUserConfig";
import IntroTeacherConfig from "@/components/setting-model/IntroTeacherConfig";
import bannerExam from "@/components/setting-model/bannerExam";
import BenefitConfig from "@/components/setting-model/BenefitConfig";
import {GET_LIST_COURSE} from "@/store/courses.module";

export default {
  name: "builder",
  data() {
    return {
      tabIndex: 0,
      items: [
        {id: 0, tab: "Top Slider"},
        // { id: 1, tab: "Countdown" },
        {id: 1, tab: "Giới thiệu Giáo viên"},
        {id: 2, tab: "Lợi ích"},
        {id: 3, tab: "khóa học hiện thị ở menu"},
        {id: 4, tab: "Footer"},
        {id: 5, tab: "Phản hồi của học viên"},
        {id: 5, tab: "Banner bài thi"},
      ],
      note:
          "Chức năng cấu hình cho Trang chủ - Trang người dùng. Ấn nút\n <code>Preview</code> để xem thay đổi của bạn."
    };
  },
  components: {
    ListTopSliderConfig,
    ListFeedbackUserConfig,
    FooterConfig,
    IntroTeacherConfig,
    BenefitConfig,
    CourseConfig,
    bannerExam
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  methods: {
    handleSubmit() {
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Cấu hình Trang chủ",titledad:'Cấu hình'}]);
    this.$store.dispatch(GET_HOME_PAGE_CONF);
    this.$store.dispatch(GET_LIST_COURSE);
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  }
};
</script>

<style>
.tab-content {
  padding: 16px;
}
</style>
