import ApiService from "@/common/api.service";

// action types
export const GET_LESSON = "course-1-1/list-lesson";
export const GET_DETAIL_LESSON = "course-1-1/detail-lesson";
export const DELETE_LESSON = "course-1-1/delete-lesson";
export const UPDATE_LESSON = "course-1-1/update-lesson";
``
// mutation types
export const SET_LESSON = "setLesson";
export const SET_DETAIL_LESSON = "setDetailLesson";

const state = {
    lessons: null,
    lesson: null,
};

const getters = {
    lessonsCourseOneToOne(state) {
        return state.lessons;
    },
    lessonCourseOneToOne(state) {
        return state.lesson;
    },
};

const actions = {
    [GET_LESSON](context, credentials) {
        return new Promise(resolve => {
            ApiService.query(GET_LESSON, credentials)
                .then(({data}) => {
                    if (data.status) {
                        context.commit(SET_LESSON, data.data);
                        resolve(data);
                    }
                })
        });
    },
    [GET_DETAIL_LESSON](context, credentials) {
        return new Promise(resolve => {
            ApiService.query(GET_DETAIL_LESSON, credentials)
                .then(({data}) => {
                    if (data.status) {
                        context.commit(SET_DETAIL_LESSON, data.data);
                        resolve(data);
                    }
                })
        });
    },
    [DELETE_LESSON](context, credentials) {
        return new Promise(resolve => {
            ApiService.post(DELETE_LESSON, credentials)
                .then(({data}) => {
                    resolve(data);
                })
        });
    },
    [UPDATE_LESSON](context, credentials) {
        return new Promise(resolve => {
            ApiService.post(UPDATE_LESSON, credentials)
                .then(({data}) => {
                    resolve(data);
                })
        });
    },
};

const mutations = {
    [SET_LESSON](state, data) {
        state.lessons = data;
    },
    [SET_DETAIL_LESSON](state, data) {
        state.lesson = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
