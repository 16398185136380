<template>
  <v-container class="ml-0 mr-0 pl-0 pr-0">
    <v-data-table
      :headers="headers"
      :items="content"
      item-key="id"
      class="elevation-1"
      single-select
      :hide-default-footer="true"
    >
      <template v-slot:item.content="{ item }">
        <v-text-field dense v-model="item.content" @input="changeContent(item)"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-row class="align-end justify-end ma-2">
      <v-btn color="accent" light @click="addAnswer">Thêm nội dung</v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    content: { type: Array, default: () => [] }
  },
  data() {
    return {
      id: 0,
      headers: [
        {
          text: "id",
          value: "id"
        },
        { text: "Nội dung", value: "content" },
        { text: "Sửa/Xóa", value: "actions" }
      ],
      // items: []
    };
  },
  components: {},
  methods: {
    addAnswer() {
      this.$emit("addRow");
      // const item = {
      //   id: this.id,
      //   content: ""
      // };
      // if (!this.items) {
      //   this.items = [];
      // }
      // this.items.push(item);
      // this.id++;
    },
    changeContent(item) {
      this.$emit("updateData", item);
    },
    deleteItem(item) {
      this.$emit("deleteItem", item);
      // const index = this.items.indexOf(item);
      // if (confirm("Are you sure you want to delete this item?")) {
      //   this.items.splice(index, 1);
      //   this.reindex();
      //   this.changeContent();
      // }
    }
    // reindex() {
    //   this.items.forEach((element, index) => {
    //     element.id = index;
    //   });
    // }
  }
};
</script>

<style lang="scss">
</style>
