import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import courses from "./courses.module";
import lessons from "./lessons.module";
import user from "./user.module";
import flashcards from "./flashcards.module";
import media from "./media.module";
import setting from "./setting.module";
import exercises from "./exercises.module";
import exams from "./exams.module";
import orders from "./orders.module";
import news from "./new.module";
import book from "./book.module";
import alerts from "./alerts.module";
import groupQuestion from "./group-questions.module";
import affiliateCode from "./affiliateCode.module";
import discountCodes from "./discountCodes.module";
import question from "./questions.module";
import lessonCourseOneToOne from "./lessonCourseOneToOne.module";
import category from "./category.module.js";
import dealCourses from "./dealCourses.module.js";
import comboCourse from "./combo-course.module.js";
import feedback from "./feedback.module.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    flashcards,
    lessons,
    courses,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    user,
    media,
    setting,
    exercises,
    exams,
    orders,
    alerts,
    news,
    groupQuestion,
    affiliateCode,
    discountCodes,
    question,
    book,
    lessonCourseOneToOne,
    category,
    dealCourses,
    feedback,
    comboCourse
  }
});
//
