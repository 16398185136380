import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";
import DatetimePicker from "vuetify-datetime-picker";
Vue.use(DatetimePicker);

import Toasted from 'vue-toasted';
Vue.use(Toasted);

Vue.config.productionTip = false;

// Vue.use(DatetimePicker);
Vue.use(CKEditor);
// Global 3rd party plugins
// import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import veeValidate from "./common/plugins/vee-validate";
// import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";

import "./common/plugins/vuetify-money.js";

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

// Ensure we checked auth before each page load.
// router.beforeEach((to, from, next) => {
//   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });


new Vue({
  router,
  store,
  i18n,
  vuetify,
  veeValidate,
  render: h => h(App)
}).$mount("#app");
