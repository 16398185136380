import ApiService from "@/common/api.service";

export const GET_LIST_DEAL_COURSE = "/deal-courses/list-course-deal";
export const GET_LIST_NOT_DEAL_COURSE = "/deal-courses/list-course-not-deal";
export const GET_DETAIL_DEAL_COURSE = "/deal-courses/detail";
export const SAVE_DEAL_COURSE = "/deal-courses/save";
export const UPDATE_DEAL_COURSE = "/deal-courses/update";
export const DELETE_DEAL_COURSE = "/deal-courses/delete";


export const SET_LIST_DEAL_COURSE = "setListDealCourse";
export const SET_LIST_NOT_DEAL_COURSE = "setListNotDealCourse";
export const SET_DETAIL_DEAL_COURSE = "setDetailDealCourse";


const state = {
    ListCoursesDeal: [],
    ListCoursesNotDeal: [],
    DealCourse: {},
};

const getters = {
    ListCoursesDeal(state) {
        return state.ListCoursesDeal;
    },
    DealCourseDetail(state) {
        return state.DealCourse;
    },
    ListCoursesNotDeal(state) {
        return state.ListCoursesNotDeal;
    },
};

const actions = {
    [GET_LIST_DEAL_COURSE](context) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_DEAL_COURSE).then(({data}) => {
                if (data.status) {
                    console.log(data,'data')
                    context.commit(SET_LIST_DEAL_COURSE, data);
                }
                resolve(data);
            });
        });
    },
    [GET_LIST_NOT_DEAL_COURSE](context) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_NOT_DEAL_COURSE).then(({data}) => {
                if (data.status) {
                    context.commit(SET_LIST_NOT_DEAL_COURSE, data);
                }
                resolve(data);
            });
        });
    },
    [GET_DETAIL_DEAL_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_DETAIL_DEAL_COURSE}`,payload).then(({data}) => {
                if (data.status) {
                    context.commit(SET_DETAIL_DEAL_COURSE, data);
                }
                resolve(data);
            });
        });
    },
    [SAVE_DEAL_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_DEAL_COURSE, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [UPDATE_DEAL_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_DEAL_COURSE, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
    [DELETE_DEAL_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(DELETE_DEAL_COURSE, payload)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    },
};

const mutations = {
    [SET_LIST_DEAL_COURSE](state, data) {
        state.ListCoursesDeal = data.data;
    },
    [SET_LIST_NOT_DEAL_COURSE](state, data) {
        state.ListCoursesNotDeal = data.data;
    },
    [SET_DETAIL_DEAL_COURSE](state, data) {
        state.DealCourse = data.data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
